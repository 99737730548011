import { HashRouter, Routes, Route } from 'react-router-dom';
import AppProvider from './providers/app-provider';
import Home from './screens/home';
import Company from './screens/records/company';
import Contract from './screens/records/contract';
import Contractor from './screens/records/contractor';
import Recruiter from './screens/records/recruiter';
import SingUp from './screens/sing-up';
import Users from './screens/users';

function App() {
  return (
    <AppProvider>
      <div className="App">
        <HashRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sing-up" element={<SingUp />} />
            <Route path="/records/company" element={<Company />} />
            <Route path="/records/account" element={<Contractor />} />
            <Route path="/records/contract" element={<Contract />} />
            <Route
              path="/records/recruiter"
              element={<Recruiter path="recruiter" />}
            />
            <Route
              path="/records/commercial"
              element={<Recruiter path="commercial" />}
            />
            <Route path="/users" element={<Users />} />
          </Routes>
        </HashRouter>
      </div>
    </AppProvider>
  );
}

export default App;
