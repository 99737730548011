import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from '../../../@types/action-type';
import { LoginResponse } from '../../../@types/login-response';
import { User } from '../../../interfaces/user';
import {
  clearLoggedInUser,
  saveLoggedInUser,
} from '../../../helpers/manage-local-storage';
import { Login } from '../../../interfaces/login';
import auth from '../../../services/auth';
import {
  authenticationFailure,
  authenticationSuccess,
  checkTokenFailure,
  checkTokenSuccess,
  Types,
} from './actions';

export function* doLogin({ payload }: ActionType<Login>) {
  try {
    const { data }: AxiosResponse<LoginResponse> = yield call(auth.login, {
      ...payload,
    });

    yield saveLoggedInUser(data);

    yield put(authenticationSuccess(data));
  } catch (error) {
    yield put(authenticationFailure());
  }
}

export function* authenticated({ payload }: ActionType<string>) {
  try {
    const { data }: AxiosResponse<User> = yield call(
      auth.authenticated,
      payload,
    );

    yield put(checkTokenSuccess(data));
  } catch (error) {
    yield put(checkTokenFailure());
  }
}

export function* logout() {
  yield clearLoggedInUser();
}

export default all([
  takeLatest(Types.AUTHENTICATION_REQUEST, doLogin),
  takeLatest(Types.CHECK_TOKEN_REQUEST, authenticated),
  takeLatest(Types.LOGOUT, logout),
]);
