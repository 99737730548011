import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyle from '../styles/GlobalStyle';
import configureStore from '../state/store';

const store = configureStore();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends React.ComponentProps<any> {
  children: React.ReactNode;
}

function AppProvider({ children }: Props) {
  GlobalStyle();

  return <Provider store={store}>{children}</Provider>;
}

export default AppProvider;
