import { all, call, put, takeLatest } from 'redux-saga/effects';
import service from '../../../services/home';
import { Types, getMenuFailure, getMenuSuccess } from './actions';

export function* findMenu() {
  try {
    const { data } = yield call(service.profile);

    yield put(getMenuSuccess(data));
  } catch (error: any) {
    yield put(getMenuFailure(error?.message));
  }
}

export default all([takeLatest(Types.GET_MENU_REQUEST, findMenu)]);
