import { ChangeEvent, useCallback } from 'react';
import MaskedInput from 'react-text-mask';
import { useAppDispatch } from '../hooks/redux';
import { updateCPF } from '../state/modules/input/actions';

function CPFInput() {
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (input: ChangeEvent<HTMLInputElement>) => {
      dispatch(updateCPF(input?.target.value));
    },
    [dispatch],
  );

  return (
    <MaskedInput
      width="100%"
      style={{
        padding: '19px 14px',
        border: '0px',
        fontSize: 16,
        outline: 'none',
      }}
      placeholder="000.000.000-00"
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
      ]}
      showMask
      onChange={handleChange}
    />
  );
}

export default CPFInput;
