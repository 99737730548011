import { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import ModeEditOutline from '@mui/icons-material/ModeEditOutline';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  OutlinedInput,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { User } from '../interfaces/user';
import { StatusUser } from '../interfaces/status-user';
import { Profile } from '../interfaces/profile';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { dynamicFormatCNPJ } from '../helpers/validate-cnpj';
import { Company } from '../interfaces/company';
import {
  findUsersRequest,
  updateUserRequest,
  updateUserResetStore,
} from '../state/modules/user/actions';
import SubmitButton from '../styles/submit-button';
import Toast from '../components/toast';

interface EditUserProps {
  defaultValues: User;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditUser({ defaultValues }: EditUserProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [status, setStatus] = useState<StatusUser>(defaultValues.status);
  const [roles, setRoles] = useState<Profile[] | undefined>(
    defaultValues.roles || [],
  );
  const [company, setCompany] = useState<Company>(
    defaultValues.company || ({} as Company),
  );

  const {
    company: {
      find: { companies },
    },
    user: {
      update: { loading, failure, success, message },
    },
  } = useAppSelector((state) => state);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelectCompany = useCallback(
    (event: SelectChangeEvent<string>) => {
      const data = companies.find((item) => item.cnpj === event.target.value);

      if (data) {
        setCompany(data);
      }
    },
    [companies],
  );

  const closeToast = useCallback(() => {
    dispatch(updateUserResetStore());
    dispatch(findUsersRequest());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [dispatch, handleClose, success]);

  const onSubmit = (data: any) => {
    dispatch(
      updateUserRequest({
        ...defaultValues,
        ...data,
        company,
        status,
        roles,
      } as User),
    );
  };

  return (
    <div>
      <Button onClick={handleClickOpen} style={{ textTransform: 'capitalize' }}>
        <ModeEditOutline /> {t('Label.edit')}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{defaultValues.name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('Label.composition.modal.subtitle.edit.user')}
            </DialogContentText>

            <Box mt={3} mb={3}>
              <FormControl fullWidth>
                <InputLabel id="status-select-label">
                  {t('Label.status')}
                </InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={status}
                  label={t('Label.status')}
                  onChange={(event) => {
                    setStatus(event.target.value as StatusUser);
                  }}
                >
                  <MenuItem value="ACTIVE">
                    {t('Label.composition.status.ACTIVE')}
                  </MenuItem>
                  <MenuItem value="BLOCKED">
                    {t('Label.composition.status.BLOCKED')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box mt={3} mb={3}>
              <FormControl fullWidth>
                <InputLabel id="profile-select-label">
                  {t('Label.profile')}
                </InputLabel>
                <Select
                  labelId="profile-select-label"
                  id="profile-select"
                  multiple
                  {...register('roles', {
                    required:
                      t('Validation.required', { field: t('Label.profile') }) ||
                      '',
                    onChange: (event) => {
                      setRoles(event.target.value as Profile[]);
                    },
                  })}
                  error={!!errors?.roles}
                  value={roles}
                  input={<OutlinedInput label={t('Label.profile')} />}
                  renderValue={(selected) =>
                    selected
                      .map((item) => t(`Label.composition.profile.${item}`))
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {['ACCOUNTANT', 'MANAGER', 'RECRUITER', 'EMPROYEE'].map(
                    (role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox
                          checked={
                            roles?.length
                              ? roles.indexOf(role as Profile) > -1
                              : false
                          }
                        />
                        <ListItemText
                          primary={t(`Label.composition.profile.${role}`)}
                        />
                      </MenuItem>
                    ),
                  )}
                </Select>
                {!!errors?.roles && (
                  <FormHelperText error>
                    {errors?.roles?.message as string}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box mt={3} mb={3}>
              <FormControl fullWidth>
                <InputLabel id="company-select-label">
                  {t('Label.company')}
                </InputLabel>
                <Select
                  labelId="company-select-label"
                  id="company-select"
                  value={company.cnpj}
                  label={t('Label.company')}
                  {...register('company', {
                    required:
                      t('Validation.required', { field: t('Label.company') }) ||
                      '',
                    onChange: (event) => handleSelectCompany(event),
                  })}
                  error={!!errors?.company}
                >
                  {companies.map((data) => (
                    <MenuItem key={data.id} value={data.cnpj}>
                      {`${dynamicFormatCNPJ(data.cnpj)} - ${data.name}`}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors?.company && (
                  <FormHelperText error>
                    {errors?.company?.message as string}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 18,
            }}
          >
            <div>
              <Button
                style={{ textTransform: 'capitalize' }}
                onClick={handleClose}
              >
                {t('Label.cancel')}
              </Button>
            </div>

            <div>
              <SubmitButton
                fullWidth
                type="submit"
                disabled={loading}
                variant="contained"
              >
                {t('Label.save')}
              </SubmitButton>
            </div>
          </DialogActions>
        </form>
      </Dialog>

      <Toast
        handleClose={closeToast}
        message={message?.message || ''}
        severity={message?.severity || 'info'}
        open={success || failure}
      />
    </div>
  );
}
