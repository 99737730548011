import axios from 'axios';
import { getToken } from '../helpers/manage-local-storage';

export function getBaseURL() {
  const { protocol, hostname, port } = window.location;
  const { REACT_APP_API_URL, REACT_APP_API_PORT } = process.env;
  const API_URL = REACT_APP_API_URL || `${protocol}//${hostname}`;
  const API_PORT = REACT_APP_API_PORT || port;

  return API_PORT ? `${API_URL}:${API_PORT}/` : `${API_URL}/`;
}

const api = axios.create({
  baseURL: 'https://api-6bccvccs2a-uc.a.run.app',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

api.defaults.headers.common['x-access-token'] = getToken();

export default api;
