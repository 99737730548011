import { ActionType } from '../../../@types/action-type';
import { LoginResponse } from '../../../@types/login-response';
import { User } from '../../../interfaces/user';
import { Types } from './actions';

export interface AuthInitialState {
  code: string;
  isAuthenticating: boolean;
  isCheckingToken: boolean;
  name: string;
  signed: boolean;
  token: string;
  failure: boolean;
}

const INITIAL_STATE: AuthInitialState = {
  code: '',
  isAuthenticating: false,
  isCheckingToken: false,
  name: '',
  signed: false,
  token: '',
  failure: false,
};

function auth(
  state: AuthInitialState = INITIAL_STATE,
  action: ActionType = {} as ActionType,
): AuthInitialState {
  switch (action.type) {
    case Types.AUTHENTICATION_FAILURE: {
      return {
        ...state,
        signed: false,
        isAuthenticating: false,
        failure: true,
      };
    }
    case Types.AUTHENTICATION_REQUEST: {
      return {
        ...state,
        isAuthenticating: true,
        signed: false,
        failure: false,
      };
    }
    case Types.AUTHENTICATION_SUCCESS: {
      const { code, name, token } = action.payload as LoginResponse;

      return {
        ...state,
        code,
        name,
        token,
        signed: true,
        isAuthenticating: false,
        failure: false,
      };
    }
    case Types.CHECK_TOKEN_FAILURE: {
      return {
        ...state,
        isCheckingToken: false,
        signed: false,
      };
    }
    case Types.CHECK_TOKEN_REQUEST: {
      return {
        ...state,
        isCheckingToken: true,
      };
    }
    case Types.CHECK_TOKEN_SUCCESS: {
      const { name } = action.payload as User;

      return {
        ...state,
        name,
        isCheckingToken: false,
        signed: true,
      };
    }
    case Types.LOGOUT: {
      return {
        ...state,
        code: '',
        isAuthenticating: false,
        isCheckingToken: false,
        name: '',
        signed: false,
        token: '',
      };
    }
    case Types.RESET_STORE: {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
}

export default auth;
