import { styled } from '@stitches/react';

export const Title = styled('h2', {
  textAlign: 'left',
  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
    },
  },
});
