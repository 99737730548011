import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  MouseEvent,
} from 'react';
import {
  Box,
  TextField,
  Link,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import {
  authenticationRequest,
  resetStore,
} from '../state/modules/auth/actions';
import SmallBoxCentered from '../elements/small-box-centered';
import { Title } from '../styles/title';
import { Login as LoginProps } from '../interfaces/login';
import SubmitButton from '../styles/submit-button';
import Toast from '../components/toast';
import HelperText from '../components/helper-text';

function Login(): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useAppDispatch();

  const [visibility, setVisibility] = useState<boolean>(false);

  const { isAuthenticating, failure } = useAppSelector((state) => state.auth);

  const onSubmit = useCallback(
    (login: unknown) => {
      dispatch(authenticationRequest(login as LoginProps));
    },
    [dispatch],
  );

  useEffect(() => {
    if (failure) {
      setTimeout(() => {
        dispatch(resetStore());
      }, 6000);
    }
  }, [dispatch, failure]);

  const handleClickShowPassword = useCallback(() => {
    setVisibility(!visibility);
  }, [visibility]);

  const handleMouseDownPassword = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    [],
  );

  return (
    <SmallBoxCentered>
      <Title align="center">Login</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <TextField
            label="E-mail"
            fullWidth
            autoComplete="email"
            {...register('email', { required: 'Campo obrigatório' })}
            error={!!errors?.email}
            helperText={
              <HelperText message={errors?.email?.message as string} />
            }
          />
        </Box>

        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={visibility ? 'text' : 'password'}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              {...register('password', {
                required: 'Campo obrigatório',
              })}
              error={!!errors?.password}
            />
          </FormControl>
        </Box>

        <Box mb={2}>
          <SubmitButton
            fullWidth
            type="submit"
            disabled={isAuthenticating}
            variant="contained"
          >
            Entrar
          </SubmitButton>
        </Box>

        <Box textAlign="center" width="100%" mb={3}>
          <Link href="/#/sing-up" variant="body1" underline="none">
            Não tenho login
          </Link>
        </Box>
      </form>

      <Toast
        open={failure}
        severity="error"
        message="Erro ao efetuar login. Se o erro persistir entre em contato com a sua contadora"
      />
    </SmallBoxCentered>
  );
}

export default Login;
