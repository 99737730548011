import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  LinearProgress,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { findUsersRequest } from '../state/modules/user/actions';
import ListUsers from '../compositions/list-users';
import { findCompaniesRequest } from '../state/modules/company/actions';
import ViewPage from '../components/view-page';

function Users() {
  const dispatch = useAppDispatch();
  const {
    user: {
      find: { failure, success, users, loading, actions },
    },
  } = useAppSelector((state) => state);

  const [pageLoad, setPageLoad] = useState<boolean>(true);

  useEffect(() => {
    dispatch(findUsersRequest());

    dispatch(findCompaniesRequest());
  }, [dispatch]);

  function render() {
    let content;

    const onSuccessDo = () =>
      success && users.length ? (
        <ListUsers data={users} actions={actions} />
      ) : (
        <div>Não tem users</div>
      );

    if (loading && pageLoad) {
      content = <LinearProgress />;
    } else {
      content = failure ? <div>Ocorreu um erro.</div> : onSuccessDo();
    }

    return content;
  }

  const changePageLoad = useCallback(() => {
    setPageLoad(false);
  }, []);

  useEffect(() => {
    if (success || failure) {
      changePageLoad();
    }
  }, [changePageLoad, failure, success]);

  return (
    <ViewPage page="USERS">
      <Box sx={{ width: '100%' }}>
        <Paper>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Usuários
            </Typography>

            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>

          <Box
            sx={{
              pl: { sm: 2 },
              pr: { xs: 2, sm: 2 },
            }}
          >
            {render()}
          </Box>
        </Paper>
      </Box>
    </ViewPage>
  );
}

export default Users;
