import { ActionType } from '../../../@types/action-type';
import { COMMON_STATE } from '../../../constants/state';
import { CommonState } from '../../../interfaces/common-state';
import { Company } from '../../../interfaces/company';
import { Types } from './actions';

interface FindCompanyResponse {
  companies: Company[];
  actions: boolean;
}

export interface FindCompanyState extends CommonState {
  companies: Company[];
  actions: boolean;
}

export const FIND_COMPANY_STATE: FindCompanyState = {
  ...COMMON_STATE,
  companies: [],
  actions: false,
};

export interface CompanyInitialState {
  create: CommonState;
  find: FindCompanyState;
}

const INITIAL_STATE: CompanyInitialState = {
  create: COMMON_STATE,
  find: FIND_COMPANY_STATE,
};

function companyReducers(
  state: CompanyInitialState = INITIAL_STATE,
  action: ActionType = {} as ActionType,
): CompanyInitialState {
  switch (action.type) {
    case Types.CREATE_COMPANY_FAILURE: {
      return {
        ...state,
        create: {
          failure: true,
          loading: false,
          message: {
            message: 'Message.http.company.create.failure',
            severity: 'error',
          },
          success: false,
        },
      };
    }
    case Types.CREATE_COMPANY_REQUEST: {
      return {
        ...state,
        create: {
          failure: false,
          loading: true,
          success: false,
        },
      };
    }
    case Types.CREATE_COMPANY_RESET: {
      return {
        ...state,
        create: {
          failure: false,
          loading: false,
          message: {
            message: '',
            severity: 'info',
          },
          success: false,
        },
      };
    }
    case Types.CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        create: {
          failure: false,
          loading: false,
          success: true,
          message: {
            message: 'Message.http.company.create.success',
            severity: 'success',
          },
        },
      };
    }
    case Types.FIND_COMPANIES_FAILURE: {
      return {
        ...state,
        find: {
          ...state.find,
          companies: [],
          failure: true,
          loading: false,
          message: {
            message: action.payload as string,
            severity: 'error',
          },
          success: false,
        },
      };
    }
    case Types.FIND_COMPANIES_REQUEST: {
      return {
        ...state,
        find: {
          ...state.find,
          companies: [],
          failure: false,
          loading: true,
          success: false,
        },
      };
    }
    case Types.FIND_COMPANIES_RESET: {
      return {
        ...state,
        find: FIND_COMPANY_STATE,
      };
    }
    case Types.FIND_COMPANIES_SUCCESS: {
      const find = action.payload as FindCompanyResponse;

      return {
        ...state,
        find: {
          ...state.find,
          companies: find.companies,
          failure: false,
          loading: false,
          success: true,
          actions: find.actions,
        },
      };
    }
    default:
      return state;
  }
}

export default companyReducers;
