import { combineReducers } from 'redux';
import auth, { AuthInitialState } from './auth/reducers';
import companyReducers, { CompanyInitialState } from './company/reducers';
import inputReducers, { InputInitialState } from './input/reducers';
import recruiterReducers, { RecruiterInitialState } from './recruiter/reducers';
import usersReducers, { UserInitialState } from './user/reducers';
import homeReducers, { HomeInitialState } from './home/reducers';

export interface RootStore {
  auth: AuthInitialState;
  company: CompanyInitialState;
  home: HomeInitialState;
  input: InputInitialState;
  recruiter: RecruiterInitialState;
  user: UserInitialState;
}

const rootReducer = combineReducers<RootStore>({
  auth,
  company: companyReducers,
  home: homeReducers,
  // TODO remover
  input: inputReducers,
  recruiter: recruiterReducers,
  user: usersReducers,
});

export default rootReducer;
