/* eslint-disable import/no-anonymous-default-export */
import { AxiosResponse } from 'axios';
import { LoginResponse } from '../@types/login-response';
import { Login } from '../interfaces/login';
import api from './api';

export default {
  login(data: Login): Promise<AxiosResponse<LoginResponse>> {
    return api.post('/sigin', data);
  },
  authenticated(token: string): Promise<AxiosResponse<boolean>> {
    return api.get('/token', { headers: { 'x-access-token': token } });
  },
};
