import React from 'react';
import { styled } from '@stitches/react';

interface LiTextProps {
  text: string;
}

const StyledSpan = styled('span', {
  fontWeight: 'normal',
  fontSize: 16,
  textTransform: 'none',
});

function Span({ text }: LiTextProps) {
  return <StyledSpan>{text}</StyledSpan>;
}

export default Span;
