import { ActionType } from '../../../@types/action-type';
import { HiredRecruiter } from '../../../interfaces/recruiter';
import { Types } from './actions';

export interface RecruiterInitialState {
  isCreating: boolean;
  created: boolean;
  failure: boolean;
  messageError: string;
  recruiters: HiredRecruiter[];
  isFinding: boolean;
  findingFailure: boolean;
  findFailureMessage: string;
}

const INITIAL_STATE: RecruiterInitialState = {
  isCreating: false,
  created: false,
  failure: false,
  messageError: '',
  recruiters: [],
  isFinding: false,
  findingFailure: false,
  findFailureMessage: '',
};

function recruiterReducers(
  state: RecruiterInitialState = INITIAL_STATE,
  action: ActionType = {} as ActionType,
): RecruiterInitialState {
  switch (action.type) {
    case Types.CREATE_RECRUITER_FAILURE: {
      const message = action.payload as string;

      return {
        ...state,
        isCreating: false,
        failure: true,
        messageError: message,
      };
    }
    case Types.CREATE_RECRUITER_REQUEST: {
      return {
        ...state,
        created: false,
        failure: false,
        isCreating: true,
      };
    }
    case Types.CREATE_RECRUITER_RESET: {
      return {
        ...state,
        isCreating: false,
        created: false,
        failure: false,
        messageError: '',
      };
    }
    case Types.CREATE_RECRUITER_SUCCESS: {
      return {
        ...state,
        created: true,
        isCreating: false,
      };
    }
    case Types.FIND_RECRUITER_BY_COMPANY_FAILURE: {
      return {
        ...state,
        isFinding: false,
        findingFailure: true,
        recruiters: [],
        findFailureMessage: action.payload as string,
      };
    }
    case Types.FIND_RECRUITER_BY_COMPANY_REQUEST: {
      return {
        ...state,
        isFinding: true,
        findingFailure: false,
        recruiters: [],
        findFailureMessage: '',
      };
    }
    case Types.FIND_RECRUITER_BY_COMPANY_RESET: {
      return {
        ...state,
        isFinding: false,
        findingFailure: false,
        recruiters: [],
        findFailureMessage: '',
      };
    }
    case Types.FIND_RECRUITER_BY_COMPANY_SUCCESS: {
      return {
        ...state,
        isFinding: true,
        findingFailure: false,
        recruiters: action.payload as HiredRecruiter[],
        findFailureMessage: '',
      };
    }
    default:
      return state;
  }
}

export default recruiterReducers;
