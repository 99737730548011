import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        code: 'en-US',
        urlLang: 'en',
        translation: {
          Label: {
            name: 'Name',
            cnpj: 'CNPJ',
            register: 'Register',
          },
          Page: {
            title: {
              records: {
                company: 'Company registration',
              },
            },
          },
          validation: {
            required: '{{field}} is required.',
            invalid: '{{field}} is invalid.',
          },
        },
      },
      pt: {
        code: 'pt-BR',
        urlLang: 'pt',
        translation: {
          Label: {
            name: 'Nome',
            cnpj: 'CNPJ',
            cpf: 'CPF',
            register: 'Cadastrar',
            supervisor: 'Supervisor',
            commission: 'Comissão',
            company: 'Empresa',
            edit: 'Editar',
            email: 'E-mail',
            cancel: 'Cancelar',
            save: 'Salvar',
            status: 'Status',
            profile: 'Perfil',
            add: 'Adicionar',
            composition: {
              recruiter: {
                company: 'Recrutador da empresa',
              },
              profile: {
                ACCOUNTANT: 'Contador(a)',
                MANAGER: 'Gerente',
                RECRUITER: 'Recrutador(a)',
                EMPROYEE: 'Funcionario(a)',
              },
              company: {
                name: 'Nome da empresa',
              },
              contractor: {
                company: 'Empresa contratante',
              },
              with: {
                supervisor: 'Com supervisor',
              },
              supervisor: {
                commission: 'Comissão do supervisor',
              },
              have: {
                fixed: 'Tem {{str}} fixo',
              },
              status: {
                BLOCKED: 'Bloqueado',
                ACTIVE: 'Ativo',
              },
              user: {
                edit: 'Editar usuário',
                company: 'Associadar a empresa',
              },
              modal: {
                subtitle: {
                  edit: {
                    user: 'Atualize o perfil e permissões de acesso no formulário abaixo',
                  },
                },
              },
            },
            fixed: 'Fixo',
            menu: {
              records: 'Cadastros',
              users: 'Usuários',
              company: 'Empresas',
              account: 'Conta / Empresa',
              commercial: 'Comercial',
              recruiter: 'Recrutador',
              allocated: 'Contrato / Alocado',
            },
          },
          Page: {
            title: {
              records: {
                company: 'Cadastro de empresa',
              },
            },
          },
          Validation: {
            required: '{{field}} é obrigatório.',
            invalid: '{{field}} é inválido.',
          },
          Title: {
            company: {
              create: 'Cadastrar empresa',
              update: 'Atualizar empresa',
            },
          },
          Message: {
            http: {
              user: {
                update: {
                  failure: 'Não foi possível atualizar o usuário',
                  success: 'Usuário atualizado com sucesso',
                },
              },
              company: {
                create: {
                  failure: 'Não foi possível adicionar a empresa',
                  success: 'Empresa adicionada com sucesso',
                },
              },
            },
          },
        },
      },
    },
    fallbackLng: 'en',
  });
