import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from '../../../@types/action-type';
import { Recruiter } from '../../../interfaces/recruiter';
import service from '../../../services/recruiter';
import {
  createRecruiterFailure,
  createRecruiterSuccess,
  findRecruiterByCompanyFailure,
  findRecruiterByCompanySuccess,
  Types,
} from './actions';

export function* create({ payload }: ActionType<Recruiter>) {
  try {
    yield call(service.create, { ...payload });

    yield put(createRecruiterSuccess());
  } catch (error: any) {
    const {
      data: { message },
    } = error.response;

    yield put(createRecruiterFailure(message));
  }
}

export function* findByCompany({ payload }: ActionType<string>) {
  try {
    const { data } = yield call(service.findByCompany, payload);

    console.log('data', data);

    yield put(findRecruiterByCompanySuccess(data));
  } catch (error: any) {
    const {
      data: { message },
    } = error.response;

    yield put(findRecruiterByCompanyFailure(message));
  }
}

export default all([
  takeLatest(Types.CREATE_RECRUITER_REQUEST, create),
  takeLatest(Types.FIND_RECRUITER_BY_COMPANY_REQUEST, findByCompany),
]);
