import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import ViewPage from '../../components/view-page';
import SmallBoxCentered from '../../elements/small-box-centered';
import SubmitButton from '../../styles/submit-button';
import { Title } from '../../styles/title';
import HelperText from '../../components/helper-text';

function Contract() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (allocated: any) => {
    // eslint-disable-next-line no-param-reassign
    console.log(allocated);
  };
  return (
    <ViewPage page="RECORDS">
      <SmallBoxCentered>
        <Title align="center">Contrato</Title>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <TextField
              label="Contratante"
              fullWidth
              autoComplete="contractor"
              {...register('contractor', { required: 'Campo obrigatório' })}
              error={!!errors?.contractor}
              helperText={
                <HelperText message={errors?.contractor?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Recrutador"
              fullWidth
              autoComplete="recruiter"
              {...register('recruiter', { required: 'Campo obrigatório' })}
              error={!!errors?.recruiter}
              helperText={
                <HelperText message={errors?.recruiter?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Alocado"
              fullWidth
              autoComplete="allocated"
              {...register('allocated', { required: 'Campo obrigatório' })}
              error={!!errors?.allocated}
              helperText={
                <HelperText message={errors?.allocated?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Valor"
              fullWidth
              autoComplete="value"
              {...register('value', { required: 'Campo obrigatório' })}
              error={!!errors?.value}
              helperText={
                <HelperText message={errors?.value?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Data de Inicio"
              fullWidth
              autoComplete="start"
              {...register('start', { required: 'Campo obrigatório' })}
              error={!!errors?.start}
              helperText={
                <HelperText message={errors?.start?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Data de Vencimento do Boleto"
              fullWidth
              autoComplete="ticketExpiration"
              {...register('ticketExpiration', {
                required: 'Campo obrigatório',
              })}
              error={!!errors?.ticketExpiration}
              helperText={
                <HelperText
                  message={errors?.ticketExpiration?.message as string}
                />
              }
            />
          </Box>

          <Box mb={2}>
            <SubmitButton
              fullWidth
              type="submit"
              // disabled={isCreating || errors?.password || created}
              variant="contained"
            >
              Cadastrar
            </SubmitButton>
          </Box>

          {/* <Toast
            handleClose={handleClose}
            message={message.message}
            severity={message.severity}
            open={created || failure}
          /> */}
        </form>
      </SmallBoxCentered>
    </ViewPage>
  );
}

export default Contract;
