import { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import HelperText from '../components/helper-text';
import { validCNPJ, dynamicFormatCNPJ } from '../helpers/validate-cnpj';
import { Company } from '../interfaces/company';

interface CompanyFormProps {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  defaultValues?: Company;
}

export default function CompanyForm({
  register,
  errors,
  defaultValues,
}: CompanyFormProps) {
  const { t } = useTranslation();
  const [cnpjValue, setCnpjValue] = useState<string>(defaultValues?.cnpj || '');

  return (
    <>
      <Box mb={2} mt={1}>
        <TextField
          label={t('Label.name')}
          fullWidth
          autoComplete="name"
          {...register('name', {
            required: t('Validation.required', { field: 'Nome' }) || '',
          })}
          defaultValue={defaultValues?.name}
          error={!!errors?.name}
          helperText={<HelperText message={errors?.name?.message as string} />}
        />
      </Box>

      <Box>
        <TextField
          label={t('Label.cnpj')}
          fullWidth
          autoComplete="name"
          placeholder="00.000.000/0000-00"
          {...register('cnpj', {
            required: t('Validation.required', { field: 'CNPJ' }) || '',
            validate: (cnpj) =>
              validCNPJ(cnpj) ||
              t('Validation.invalid', { field: 'CNPJ' }) ||
              '',
            onChange: (event) => {
              const value = dynamicFormatCNPJ(event.target.value || '');
              setCnpjValue(value as string);
            },
          })}
          error={!!errors?.cnpj}
          value={cnpjValue}
          helperText={<HelperText message={errors?.cnpj?.message as string} />}
        />
      </Box>
    </>
  );
}
