import { Alert, AlertColor, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ToastProps {
  open: boolean;
  message: string;
  handleClose?: () => void;
  severity: AlertColor;
}

function Toast({ open, message, handleClose, severity }: ToastProps) {
  const vertical = 'top';
  const horizontal = 'right';

  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={severity === 'success' ? 2000 : 5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {t(message)}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
