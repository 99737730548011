import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { User } from '../interfaces/user';
import EditUser from './edit-user';
import { ListTable } from '../interfaces/list-table';

export default function ListUsers({ data, actions }: ListTable<User>) {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        padding="normal"
        aria-labelledby="tableTitle"
        size="medium"
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('Label.name')}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('Label.email')}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('Label.company')}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('Label.status')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((user) => (
            <TableRow
              style={{
                height: 53,
              }}
            >
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.company?.name}</TableCell>
              <TableCell>
                {t(`Label.composition.status.${user.status}`)}
              </TableCell>
              <TableCell align="right">
                {actions && <EditUser defaultValues={user} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
