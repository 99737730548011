import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import ViewPage from '../../components/view-page';
import { useAppSelector } from '../../hooks/redux';
import ListCompany from '../../compositions/list-company';
import ModalCompanyForm from '../../compositions/modal-company-form';

function Company(): React.ReactElement {
  const { t } = useTranslation();

  const { companies, success, loading, failure } = useAppSelector(
    (state) => state.company.find,
  );

  function render() {
    let content;

    const onSuccessDo = () =>
      success && companies.length ? (
        <ListCompany data={companies} actions />
      ) : (
        <div>Não tem users</div>
      );

    if (loading) {
      content = <LinearProgress />;
    } else {
      content = failure ? <div>Ocorreu um erro.</div> : onSuccessDo();
    }

    return content;
  }

  return (
    <ViewPage page="USERS">
      <Box sx={{ width: '100%' }}>
        <Paper>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {t('Label.company')}
            </Typography>

            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>

          <Box
            sx={{
              pl: { sm: 2 },
              pr: { xs: 2, sm: 2 },
            }}
            alignItems="end"
            textAlign="right"
          >
            <ModalCompanyForm action="create" />
          </Box>

          <Box
            sx={{
              pl: { sm: 2 },
              pr: { xs: 2, sm: 2 },
            }}
          >
            {render()}
          </Box>
        </Paper>
      </Box>
    </ViewPage>
  );
}

export default Company;
