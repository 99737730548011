import { styled } from '../configs/stitches.config';

const Container = styled('div', {
  'display': 'flex',
  'justifySelf': 'auto',
  'margin': '0 auto',
  'justifyContent': 'center',
  '@xSmall': {
    width: '540px',
  },
  '@small': {
    width: '720px',
  },
  '@medium': {
    width: '960px',
  },
  '@large': {
    width: '1140px',
  },
  '@xLarge': {
    width: '1320px',
  },
});

export default Container;
