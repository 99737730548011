import { ActionType } from '../../../@types/action-type';
import { COMMON_STATE } from '../../../constants/state';
import { CommonState } from '../../../interfaces/common-state';
import { ProfileMenu } from '../../../interfaces/profile-menu';
import { Types } from './actions';

interface FindMenuResponse {
  menu: ProfileMenu[];
  userName: string;
}

interface ProfileMenuState extends CommonState {
  menu: ProfileMenu[];
  userName: string;
}

const PROFILE_MENU_STATE: ProfileMenuState = {
  ...COMMON_STATE,
  menu: [],
  userName: '',
};

export interface HomeInitialState {
  profileMenu: ProfileMenuState;
}

const INITIAL_STATE: HomeInitialState = {
  profileMenu: PROFILE_MENU_STATE,
};

function homeReducers(
  state: HomeInitialState = INITIAL_STATE,
  action: ActionType = {} as ActionType,
): HomeInitialState {
  switch (action.type) {
    case Types.GET_MENU_FAILURE: {
      return {
        ...state,
        profileMenu: {
          ...PROFILE_MENU_STATE,
          failure: true,
          message: {
            message: action.payload as string,
            severity: 'error',
          },
        },
      };
    }
    case Types.GET_MENU_REQUEST: {
      return {
        ...state,
        profileMenu: {
          ...PROFILE_MENU_STATE,
          loading: true,
        },
      };
    }
    case Types.GET_MENU_RESET: {
      return {
        ...state,
        profileMenu: {
          ...state.profileMenu,
          ...COMMON_STATE,
        },
      };
    }
    case Types.GET_MENU_SUCCESS: {
      const find = action.payload as FindMenuResponse;
      return {
        ...state,
        profileMenu: {
          ...PROFILE_MENU_STATE,
          menu: find.menu,
          userName: find.userName,
        },
      };
    }
    default:
      return state;
  }
}

export default homeReducers;
