import { Button } from '@mui/material';
import { styled } from '@stitches/react';

const SubmitButton = styled(Button, {
  '&.MuiButton-root': {
    backgroundColor: '#02263d',
    textTransform: 'capitalize',
    padding: 16,
  },
});

export default SubmitButton;
