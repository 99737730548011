import { ActionType } from '../../../@types/action-type';
import { Recruiter } from '../../../interfaces/recruiter';

export const Types = {
  CREATE_RECRUITER_FAILURE: '@recruiter/CREATE_RECRUITER_FAILURE',
  CREATE_RECRUITER_REQUEST: '@recruiter/CREATE_RECRUITER_REQUEST',
  CREATE_RECRUITER_RESET: '@recruiter/CREATE_RECRUITER_RESET',
  CREATE_RECRUITER_SUCCESS: '@recruiter/CREATE_RECRUITER_SUCCESS',

  FIND_RECRUITER_BY_COMPANY_FAILURE:
    '@recruiter/FIND_RECRUITER_BY_COMPANY_FAILURE',
  FIND_RECRUITER_BY_COMPANY_REQUEST:
    '@recruiter/FIND_RECRUITER_BY_COMPANY_REQUEST',
  FIND_RECRUITER_BY_COMPANY_RESET: '@recruiter/FIND_RECRUITER_BY_COMPANY_RESET',
  FIND_RECRUITER_BY_COMPANY_SUCCESS:
    '@recruiter/FIND_RECRUITER_BY_COMPANY_SUCCESS',
};

export function createRecruiterFailure(message: string) {
  return {
    type: Types.CREATE_RECRUITER_FAILURE,
    payload: message,
  };
}

export function createRecruiterRequest(
  recruiter: Recruiter,
): ActionType<Recruiter> {
  return {
    type: Types.CREATE_RECRUITER_REQUEST,
    payload: recruiter,
  };
}

export function createRecruiterReset() {
  return {
    type: Types.CREATE_RECRUITER_RESET,
    payload: {},
  };
}

export function createRecruiterSuccess() {
  return {
    type: Types.CREATE_RECRUITER_SUCCESS,
    payload: {},
  };
}

export function findRecruiterByCompanyFailure(message: string) {
  return {
    type: Types.FIND_RECRUITER_BY_COMPANY_FAILURE,
    payload: message,
  };
}

export function findRecruiterByCompanyRequest(
  cnpj: string,
): ActionType<string> {
  return {
    type: Types.FIND_RECRUITER_BY_COMPANY_REQUEST,
    payload: cnpj,
  };
}

export function findRecruiterByCompanyReset() {
  return {
    type: Types.FIND_RECRUITER_BY_COMPANY_RESET,
    payload: {},
  };
}

export function findRecruiterByCompanySuccess(
  recruiters: Recruiter[],
): ActionType<Recruiter[]> {
  return {
    type: Types.FIND_RECRUITER_BY_COMPANY_SUCCESS,
    payload: recruiters,
  };
}
