import { AxiosResponse } from 'axios';
import { Company } from '../interfaces/company';
import api from './api';

export default {
  create(company: Company): Promise<AxiosResponse> {
    return api.post('/companies', company);
  },
  findAll(): Promise<AxiosResponse<Company[]>> {
    return api.get('/companies');
  },
};
