import { ActionType } from '../../../@types/action-type';
import { Company } from '../../../interfaces/company';

export const Types = {
  CREATE_COMPANY_FAILURE: '@company/CREATE_COMPANY_FAILURE',
  CREATE_COMPANY_REQUEST: '@company/CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_RESET: '@company/CREATE_COMPANY_RESET',
  CREATE_COMPANY_SUCCESS: '@company/CREATE_COMPANY_SUCCESS',

  FIND_COMPANIES_FAILURE: '@company/FIND_COMPANIES_FAILURE',
  FIND_COMPANIES_REQUEST: '@company/FIND_COMPANIES_REQUEST',
  FIND_COMPANIES_RESET: '@company/FIND_COMPANIES_RESET',
  FIND_COMPANIES_SUCCESS: '@company/FIND_COMPANIES_SUCCESS',
};

export function createCompanyFailure(message: string) {
  return {
    type: Types.CREATE_COMPANY_FAILURE,
    payload: message,
  };
}

export function createCompanyRequest(company: Company): ActionType<Company> {
  return {
    type: Types.CREATE_COMPANY_REQUEST,
    payload: company,
  };
}

export function createCompanyResetStore() {
  return {
    type: Types.CREATE_COMPANY_RESET,
    payload: {},
  };
}

export function createCompanySuccess() {
  return {
    type: Types.CREATE_COMPANY_SUCCESS,
    payload: {},
  };
}

export function findCompaniesFailure(message: string) {
  return {
    type: Types.FIND_COMPANIES_FAILURE,
    payload: message,
  };
}

export function findCompaniesRequest() {
  return {
    type: Types.FIND_COMPANIES_REQUEST,
    payload: {},
  };
}

export function findCompaniesResetStore() {
  return {
    type: Types.FIND_COMPANIES_RESET,
    payload: {},
  };
}

export function findCompaniesSuccess(
  companies: Company[],
): ActionType<Company[]> {
  return {
    type: Types.FIND_COMPANIES_SUCCESS,
    payload: companies,
  };
}
