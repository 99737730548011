import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ListTable } from '../interfaces/list-table';
import { Company } from '../interfaces/company';
import { dynamicFormatCNPJ } from '../helpers/validate-cnpj';
import ModalCompanyForm from './modal-company-form';

export default function ListCompany({ actions, data }: ListTable<Company>) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        padding="normal"
        aria-labelledby="tableTitle"
        size="medium"
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('Label.cnpj')}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('Label.name')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((company) => (
            <TableRow
              style={{
                height: 53,
              }}
              key={company.id}
            >
              <TableCell>{dynamicFormatCNPJ(company.cnpj)}</TableCell>
              <TableCell>{company.name}</TableCell>
              <TableCell align="right">
                {actions && (
                  <ModalCompanyForm company={company} action="update" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
