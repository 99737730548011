import { ActionType } from '../../../@types/action-type';
import { ProfileMenu } from '../../../interfaces/profile-menu';

export const Types = {
  GET_MENU_FAILURE: '@home/GET_MENU_FAILURE',
  GET_MENU_REQUEST: '@home/GET_MENU_REQUEST',
  GET_MENU_RESET: '@home/GET_MENU_RESET',
  GET_MENU_SUCCESS: '@home/GET_MENU_SUCCESS',
};

export function getMenuFailure(message: string) {
  return {
    type: Types.GET_MENU_FAILURE,
    payload: message,
  };
}

export function getMenuRequest() {
  return {
    type: Types.GET_MENU_REQUEST,
    payload: {},
  };
}

export function getMenuReset() {
  return {
    type: Types.GET_MENU_RESET,
    payload: {},
  };
}

export function getMenuSuccess(data: ProfileMenu[]): ActionType<ProfileMenu[]> {
  return {
    type: Types.GET_MENU_SUCCESS,
    payload: data,
  };
}
