import { FC, PropsWithChildren } from 'react';
import { styled } from '@stitches/react';

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  variants: {
    window: {
      small: {
        height: 'calc(100vh - 120px)',
      },
      medium: {
        marginTop: 30,
        marginBottom: 60,
      },
    },
  },
});

const Div = styled('div', {
  width: '350px',
  alignSelf: 'center',
  padding: '20px 100px',
  boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%)
  `,
});

interface SmallBoxCenteredProps {
  size?: 'small' | 'medium';
}

// eslint-disable-next-line react/function-component-definition
const SmallBoxCentered: FC<PropsWithChildren<SmallBoxCenteredProps>> = ({
  children,
  size = 'small',
}) => (
  <Container window={size}>
    <Div>{children}</Div>
  </Container>
);

export default SmallBoxCentered;
