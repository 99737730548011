import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Toast from '../../components/toast';
import ViewPage from '../../components/view-page';
import SmallBoxCentered from '../../elements/small-box-centered';
import { dynamicFormatCNPJ, validCNPJ } from '../../helpers/validate-cnpj';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { resetStore } from '../../state/modules/auth/actions';
import { createCompanyResetStore } from '../../state/modules/company/actions';
import SubmitButton from '../../styles/submit-button';
import { Title } from '../../styles/title';
import HelperText from '../../components/helper-text';

function Contractor() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { success, loading, failure, message } = useAppSelector(
    (state) => state.company.create,
  );

  const { t } = useTranslation();

  const [cnpjValue, setCnpjValue] = useState<string>('');

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(createCompanyResetStore());
  }, [dispatch]);

  const handleSuccess = useCallback(() => {
    setCnpjValue('');
    reset();

    setTimeout(() => handleClose, 6000);
  }, [handleClose, reset]);

  const handleFailure = useCallback(() => {
    setTimeout(() => handleClose, 6000);
  }, [handleClose]);

  const onSubmit = (company: any) => {
    console.log(company);
    dispatch(resetStore());
  };

  useEffect(() => {
    if (success) {
      handleSuccess();
    }
  }, [success, handleSuccess]);

  useEffect(() => {
    if (failure) {
      handleFailure();
    }
  }, [failure, handleFailure]);

  return (
    <ViewPage page="RECORDS">
      <SmallBoxCentered>
        <Title align="center">Nova Conta / Empresa</Title>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <TextField
              label="Nome"
              fullWidth
              autoComplete="name"
              {...register('name', { required: 'Campo obrigatório' })}
              error={!!errors?.name}
              helperText={
                <HelperText message={errors?.name?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="CNPJ"
              fullWidth
              autoComplete="name"
              placeholder="00.000.000/0000-00"
              {...register('cnpj', {
                required: 'CNPJ é obrigatório',
                validate: (cnpj) =>
                  validCNPJ(cnpj) ||
                  t('Validation.invalid', { field: 'CNPJ' }) ||
                  '',
                onChange: (event) => {
                  const value = dynamicFormatCNPJ(event.target.value || '');
                  setCnpjValue(value as string);
                },
              })}
              value={cnpjValue}
              error={!!errors?.cnpj}
              helperText={
                <HelperText message={errors?.cnpj?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="E-mail"
              fullWidth
              type="email"
              autoComplete="email"
              {...register('email', { required: 'E-mail é obrigatório' })}
              error={!!errors?.email}
              helperText={
                <HelperText message={errors?.email?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Telefone"
              fullWidth
              autoComplete="phone"
              {...register('phone', { required: 'Telefone é obrigatório' })}
              error={!!errors?.phone}
              helperText={
                <HelperText message={errors?.phone?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Fee"
              fullWidth
              type="number"
              autoComplete="fee"
              {...register('fee', { required: 'Fee é obrigatório' })}
              error={!!errors?.fee}
              helperText={
                <HelperText message={errors?.fee?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            {/* <TextField
              label="Comercial"
              fullWidth
              type="number"
              autoComplete="manager"
              {...register('manager', {
                required: 'Comercial é obrigatório',
              })}
              error={!!errors?.manager}
              helperText={
                <HelperText
                  message={errors?.ticketExpiration?.message as string}
                />
              }
            /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Age</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={10}
                label="Comercial"
                onChange={(x) => console.log(x)}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box mb={2}>
            <SubmitButton
              fullWidth
              type="submit"
              disabled={loading || !!errors?.password || success}
              variant="contained"
            >
              Cadastrar
            </SubmitButton>
          </Box>

          <Toast
            handleClose={handleClose}
            message={message?.message || ''}
            severity={message?.severity || 'info'}
            open={success || failure}
          />
        </form>
      </SmallBoxCentered>
    </ViewPage>
  );
}

export default Contractor;
