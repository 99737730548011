/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { ChangeEvent, useCallback } from 'react';
import MaskedInput from 'react-text-mask';
import { useAppDispatch } from '../hooks/redux';
import { updateCNPJ } from '../state/modules/input/actions';

function CNPJInput() {
  const dispatch = useAppDispatch();

  const handleChangeCnpj = useCallback(
    (input: ChangeEvent<HTMLInputElement>) => {
      dispatch(updateCNPJ(input?.target.value));
    },
    [dispatch],
  );

  return (
    <MaskedInput
      width="100%"
      style={{
        padding: '19px 14px',
        border: '0px',
        fontSize: 16,
        outline: 'none',
      }}
      placeholder="00.000.000/0000-00"
      mask={[
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '/',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
      ]}
      showMask
      onChange={handleChangeCnpj}
    />
  );
}

export default CNPJInput;
