import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createUserFailure,
  createUserSuccess,
  findUsersFailure,
  findUsersSuccess,
  Types,
  updateUserFailure,
  updateUserSuccess,
} from './actions';
import service from '../../../services/users';
import { ActionType } from '../../../@types/action-type';
import { CreateUser } from '../../../interfaces/create-user';
import { User } from '../../../interfaces/user';

export function* create({ payload }: ActionType<CreateUser>) {
  try {
    yield call(service.create, { ...payload });

    yield put(createUserSuccess());
  } catch (error) {
    yield put(createUserFailure());
  }
}

export function* findAll() {
  try {
    const { data } = yield call(service.findAll);

    yield put(findUsersSuccess(data));
  } catch (error) {
    yield put(findUsersFailure());
  }
}

export function* update({ payload }: ActionType<User>) {
  try {
    yield call(service.update, { ...payload });

    yield put(updateUserSuccess());
  } catch (error) {
    yield put(updateUserFailure());
  }
}

export default all([
  takeLatest(Types.CREATE_USER_REQUEST, create),
  takeLatest(Types.FIND_USERS_REQUEST, findAll),
  takeLatest(Types.UPDATE_USER_REQUEST, update),
]);
