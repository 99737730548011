import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Link,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Main from '../compositions/main';
import SmallBoxCentered from '../elements/small-box-centered';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { CreateUser } from '../interfaces/create-user';
import {
  createUserRequest,
  createUserResetStore,
} from '../state/modules/user/actions';
import { Title } from '../styles/title';
import SubmitButton from '../styles/submit-button';
import Toast from '../components/toast';
import { Message } from '../interfaces/message';
import HelperText from '../components/helper-text';

function SingUp() {
  const navigate = useNavigate();

  const [message, setMessage] = useState<Message>({} as Message);
  const [visibility, setVisibility] = useState<boolean>(false);

  const { created, failure, isCreating } = useAppSelector(
    (state) => state.user,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    if (created) {
      dispatch(createUserResetStore());
      navigate('/');
    }

    dispatch(createUserResetStore());
  }, [created, dispatch, navigate]);

  const handleSuccess = useCallback(() => {
    setMessage({
      severity: 'success',
      message: 'Login criado com sucesso',
    });

    setTimeout(() => handleClose, 6000);
  }, [handleClose]);

  const handleFailure = useCallback(() => {
    setMessage({
      severity: 'info',
      message: 'O login não foi criado. Entre em contato com sua contadora',
    });

    setTimeout(() => handleClose, 6000);
  }, [handleClose]);

  useEffect(() => {
    dispatch(createUserResetStore());
  }, [dispatch]);

  useEffect(() => {
    if (created) {
      handleSuccess();
    }
  }, [created, handleSuccess]);

  useEffect(() => {
    if (failure) {
      handleFailure();
    }
  }, [failure, handleFailure]);

  const onSubmit = useCallback(
    (user: unknown) => {
      dispatch(createUserRequest(user as CreateUser));
    },
    [dispatch],
  );

  const handleClickShowPassword = useCallback(() => {
    setVisibility(!visibility);
  }, [visibility]);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    [],
  );

  return (
    <Main page="HIDDEN">
      <SmallBoxCentered>
        <Title align="center">Criar login</Title>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <TextField
              label="Nome"
              fullWidth
              autoComplete="name"
              {...register('name', { required: 'Campo obrigatório' })}
              error={!!errors?.name}
              helperText={
                <HelperText message={errors?.name?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="E-mail"
              fullWidth
              autoComplete="email"
              {...register('email', { required: 'Campo obrigatório' })}
              error={!!errors?.email}
              helperText={
                <HelperText message={errors?.email?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={visibility ? 'text' : 'password'}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {visibility ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                {...register('password', {
                  required: 'Campo obrigatório',
                })}
                error={!!errors?.password}
              />
            </FormControl>
          </Box>

          <Box mb={2}>
            <SubmitButton
              fullWidth
              type="submit"
              disabled={isCreating || !!errors?.password || created}
              variant="contained"
            >
              Criar Login
            </SubmitButton>
          </Box>

          <Box textAlign="center" width="100%" mb={3}>
            <Link href="/" variant="body1" underline="none">
              Já tenho login
            </Link>
          </Box>

          <Toast
            handleClose={handleClose}
            message={message.message}
            severity={message.severity}
            open={created || failure}
          />
        </form>
      </SmallBoxCentered>
    </Main>
  );
}

export default SingUp;
