import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { styled } from '@stitches/react';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { logOut } from '../state/modules/auth/actions';
import Span from '../elements/span';

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});

function LoggedInUser(): React.ReactElement {
  const navigate = useNavigate();
  const { name } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const exit = useCallback(() => {
    handleClose();
    dispatch(logOut());

    navigate('/');
  }, [dispatch, navigate]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {name && (
        <Container>
          <Button
            size="large"
            aria-label="account of current user"
            aria-controls="menu-user"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Span text={name.split(' ')[0]} />
          </Button>

          <Menu
            id="menu-user"
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchor)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>{name}</MenuItem>
            <MenuItem onClick={exit}>Sair</MenuItem>
          </Menu>
        </Container>
      )}
    </>
  );
}

export default LoggedInUser;
