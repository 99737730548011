import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import ViewPage from '../../components/view-page';
import SmallBoxCentered from '../../elements/small-box-centered';
import SubmitButton from '../../styles/submit-button';
import { Title } from '../../styles/title';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  createRecruiterRequest,
  createRecruiterReset,
  findRecruiterByCompanyRequest,
} from '../../state/modules/recruiter/actions';
import { Message } from '../../interfaces/message';
import Toast from '../../components/toast';
import CNPJInput from '../../elements/cnpj-input';
import { validCNPJ } from '../../helpers/validate-cnpj';
import { findCompaniesRequest } from '../../state/modules/company/actions';
import CPFInput from '../../elements/cpf-input';
import { validateCPF } from '../../helpers/validate-cpf';
import { updateCNPJ, updateCPF } from '../../state/modules/input/actions';
import HelperText from '../../components/helper-text';

interface RecruiterProps {
  path: 'commercial' | 'recruiter';
}

function Recruiter({ path }: RecruiterProps) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { created, messageError, failure, isCreating, recruiters } =
    useAppSelector(({ recruiter }) => recruiter);
  const {
    find: { companies },
  } = useAppSelector(({ company }) => company);
  const { cnpj, cpf } = useAppSelector(({ input }) => input);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [message, setMessage] = useState<Message>({} as Message);
  const [workTo, setWorkTo] = useState<string>('');

  const onSubmit = (recruiter: any) => {
    dispatch(
      createRecruiterRequest({
        ...recruiter,
        cnpj: cnpj.replace(/\D/g, ''),
        cpf: cpf.replace(/\D/g, ''),
      }),
    );
  };

  const handleClose = useCallback(() => {
    dispatch(createRecruiterReset());
  }, [dispatch]);

  const handleSuccess = useCallback(() => {
    reset();
    setWorkTo('');
    dispatch(updateCNPJ(''));
    dispatch(updateCPF(''));
    setMessage({
      severity: 'success',
      message: 'Recrutador cadastrado com sucesso',
    });

    setTimeout(() => handleClose, 6000);
  }, [dispatch, handleClose, reset]);

  const handleFailure = useCallback(() => {
    setMessage({
      severity: 'info',
      message: messageError || 'Erro ao cadastrar empresa',
    });

    setTimeout(() => handleClose, 6000);
  }, [handleClose, messageError]);

  const handleChangeWorkTo = useCallback(
    (event: SelectChangeEvent<typeof workTo>) => {
      setWorkTo(event.target.value);

      register('cnpjContractor', { value: event.target.value });
    },
    [register],
  );

  useEffect(() => {
    if (created) {
      handleSuccess();
    }
  }, [created, handleSuccess]);

  useEffect(() => {
    if (failure) {
      handleFailure();
    }
  }, [failure, handleFailure]);

  useEffect(() => {
    dispatch(findCompaniesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (workTo) {
      dispatch(findRecruiterByCompanyRequest(workTo));
    }
  }, [dispatch, workTo]);

  return (
    <ViewPage page="RECORDS">
      <SmallBoxCentered size="medium">
        <Title align="center">
          {path === 'commercial' ? 'Comercial' : 'Recrutador'}
        </Title>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <TextField
              label={t('Label.name')}
              fullWidth
              autoComplete="name"
              {...register('name', { required: 'Campo obrigatório' })}
              error={!!errors?.name}
              helperText={
                <HelperText message={errors?.name?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label={t('Label.cpf')}
              fullWidth
              autoComplete="CPF"
              placeholder="000.000.000-00"
              {...register('cpf', {
                required:
                  t('Validation.required', { field: t('Label.cpf') }) || '',
                validate: () =>
                  validateCPF(cpf) ||
                  t('Validation.invalid', { field: t('Label.cpf') }) ||
                  '',
              })}
              InputProps={{
                inputComponent: CPFInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors?.cpf}
              helperText={
                <HelperText message={errors?.cpf?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label={t('Label.composition.company.name')}
              fullWidth
              autoComplete="corporateName"
              {...register('corporateName', { required: 'Campo obrigatório' })}
              error={!!errors?.corporateName}
              helperText={
                <HelperText
                  message={errors?.corporateName?.message as string}
                />
              }
            />
          </Box>

          <Box mb={2}>
            <TextField
              label={t('Label.cnpj')}
              fullWidth
              autoComplete="CNPJ"
              placeholder="00.000.000/0000-00"
              {...register('cnpj', {
                required:
                  t('Validation.required', { field: t('Label.cnpj') }) || '',
                validate: () =>
                  validCNPJ(cnpj) ||
                  t('Validation.invalid', { field: t('Label.cnpj') }) ||
                  '',
                onChange: (text) => {
                  console.log(text);
                },
              })}
              InputProps={{
                inputComponent: CNPJInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors?.cnpj}
              helperText={
                <HelperText message={errors?.cnpj?.message as string} />
              }
            />
          </Box>

          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel id="company-select-label">
                {t('Label.composition.contractor.company')}
              </InputLabel>
              <Select
                labelId="company-select-label"
                id="companyselect"
                value={workTo}
                label={t('Label.composition.contractor.company')}
                onChange={handleChangeWorkTo}
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.cnpj}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box mb={2}>
            <TextField
              label={t('Label.commission')}
              type="number"
              fullWidth
              autoComplete="commission"
              {...register('commission')}
              error={!!errors?.commission}
              helperText={
                <HelperText
                  message={errors?.ticketExpiration?.message as string}
                />
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mb={2}>
            <TextField
              label={t('Label.fixed')}
              fullWidth
              type="number"
              autoComplete="fixed"
              {...register('fixed')}
              error={!!errors?.fixed}
              helperText={
                <HelperText
                  message={errors?.ticketExpiration?.message as string}
                />
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel id="hired-select-label">
                {t('Label.supervisor')}
              </InputLabel>
              <Select
                labelId="hired-select-label"
                id="hired-select"
                label={t('Label.supervisor')}
                {...register('managerId')}
              >
                {recruiters.length ? (
                  recruiters.map((hired) => (
                    <MenuItem key={hired.id} value={hired.id}>
                      {hired.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    {`Selecione "Recrutador da empresa antes"`}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box mb={2}>
            <TextField
              label={t('Label.composition.supervisor.commission')}
              fullWidth
              type="number"
              autoComplete="managerCommission"
              {...register('managerCommission')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mb={2}>
            <SubmitButton
              fullWidth
              type="submit"
              disabled={isCreating || !!errors?.password || created}
              variant="contained"
            >
              {t('Label.register')}
            </SubmitButton>
          </Box>

          <Toast
            handleClose={handleClose}
            message={message.message}
            severity={message.severity}
            open={created || failure}
          />
        </form>
      </SmallBoxCentered>
    </ViewPage>
  );
}

export default Recruiter;
