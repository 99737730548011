import { ActionType } from '../../../@types/action-type';
import { CreateUser } from '../../../interfaces/create-user';
import { User } from '../../../interfaces/user';

export const Types = {
  CREATE_USER_FAILURE: '@user/CREATE_USER_FAILURE',
  CREATE_USER_REQUEST: '@user/CREATE_USER_REQUEST',
  CREATE_USER_RESET: '@user/CREATE_USER_RESET',
  CREATE_USER_SUCCESS: '@user/CREATE_USER_SUCCESS',

  FIND_USERS_FAILURE: '@user/FIND_USERS_FAILURE',
  FIND_USERS_REQUEST: '@user/FIND_USERS_REQUEST',
  FIND_USERS_RESET: '@user/FIND_USERS_RESET',
  FIND_USERS_SUCCESS: '@user/FIND_USERS_SUCCESS',

  UPDATE_USER_FAILURE: '@user/UPDATE_USER_FAILURE',
  UPDATE_USER_REQUEST: '@user/UPDATE_USER_REQUEST',
  UPDATE_USER_RESET: '@user/UPDATE_USER_RESET',
  UPDATE_USER_SUCCESS: '@user/UPDATE_USER_SUCCESS',
};

export function createUserFailure() {
  return {
    type: Types.CREATE_USER_FAILURE,
    payload: {},
  };
}

export function createUserRequest(user: CreateUser): ActionType<CreateUser> {
  return {
    type: Types.CREATE_USER_REQUEST,
    payload: user,
  };
}

export function createUserResetStore() {
  return {
    type: Types.CREATE_USER_RESET,
    payload: {},
  };
}

export function createUserSuccess() {
  return {
    type: Types.CREATE_USER_SUCCESS,
    payload: {},
  };
}

export function findUsersFailure() {
  return {
    type: Types.FIND_USERS_FAILURE,
    payload: {},
  };
}

export function findUsersRequest() {
  return {
    type: Types.FIND_USERS_REQUEST,
    payload: {},
  };
}

export function findUsersResetStore() {
  return {
    type: Types.FIND_USERS_RESET,
    payload: {},
  };
}

export function findUsersSuccess(user: User[]): ActionType<User[]> {
  return {
    type: Types.FIND_USERS_SUCCESS,
    payload: user,
  };
}

export function updateUserFailure() {
  return {
    type: Types.UPDATE_USER_FAILURE,
    payload: {},
  };
}

export function updateUserRequest(user: User): ActionType<User> {
  return {
    type: Types.UPDATE_USER_REQUEST,
    payload: user,
  };
}

export function updateUserResetStore() {
  return {
    type: Types.UPDATE_USER_RESET,
    payload: {},
  };
}

export function updateUserSuccess() {
  return {
    type: Types.UPDATE_USER_SUCCESS,
    payload: {},
  };
}
