/* eslint-disable import/no-anonymous-default-export */
import { AxiosResponse } from 'axios';
import { CreateUser } from '../interfaces/create-user';
import api from './api';
import { User } from '../interfaces/user';

export default {
  create(data: CreateUser): Promise<AxiosResponse> {
    return api.post('/users', data);
  },
  findAll() {
    return api.get('/users');
  },
  update(data: User): Promise<AxiosResponse> {
    return api.put(`/users/${data.id}`, data);
  },
};
