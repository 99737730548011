import React from 'react';
import { styled } from '@stitches/react';
import logo from '../assets/logo-hs-bomfim-contabilidade.jpeg';

const Container = styled('div', {
  display: 'flex',
  width: '210px',
  backgroundImage: `url(${logo})`,
  backgroundPosition: 0,
  backgroundRepeat: 'no-repeat',
  padding: '5px 0px',
  position: 'absolute',
  height: '40px',
  marginTop: '-30px',
  fontSize: '20px',
});

function Logo() {
  return <Container />;
}

export default Logo;
