import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import logger from 'redux-logger';

import rootReducer from './modules/root-reducer';
import rootSaga from './modules/root-saga';

function configureStore(): Store {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

  sagaMiddleware.run(rootSaga);
  return store;
}

export default configureStore;
