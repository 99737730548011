import { all } from 'redux-saga/effects';
import auth from './auth/sagas';
import companies from './company/sagas';
import home from './home/sagas';
import recruiter from './recruiter/sagas';
import user from './user/sagas';

export default function* rootSaga(): any {
  return yield all([auth, companies, home, recruiter, user]);
}
