import { createStitches } from '@stitches/react';

export const {
  config,
  createTheme,
  css,
  getCssText,
  keyframes,
  styled,
  theme,
} = createStitches({
  theme: {
    colors: {},
    space: {},
    fontSizes: {},
    fonts: {},
    fontWeights: {},
    lineHeights: {},
    letterSpacings: {},
    sizes: {},
    borderWidths: {},
    borderStyles: {},
    radii: {},
    shadows: {},
    zIndices: {},
    transitions: {},
  },
  media: {
    xSmall: '(min-width: 576px)',
    small: '(min-width: 768px)',
    medium: '(min-width: 992px)',
    large: '(min-width: 1200px)',
    xLarge: '(min-width: 1400px) ',
  },
  utils: {},
});
