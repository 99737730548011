import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { LinearProgress } from '@mui/material';
import Login from '../compositions/login';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { checkTokenRequest } from '../state/modules/auth/actions';
import { getToken } from '../helpers/manage-local-storage';
import Main from '../compositions/main';
import { CurrentRoute } from '../@types/current-route';
import { getMenuRequest } from '../state/modules/home/actions';
import { findCompaniesRequest } from '../state/modules/company/actions';

interface ViewPageProps {
  children: ReactNode;
  page: CurrentRoute;
}

function ViewPage({ children, page }: ViewPageProps): ReactElement {
  const {
    auth: { signed, isCheckingToken },
    home: {
      profileMenu: { loading, menu, failure },
    },
    company: {
      find: { companies },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();
  const [isPageLoad, setPageLoad] = useState<boolean>(true);

  const getMenu = useCallback(() => {
    setPageLoad(false);
    if (!menu.length) {
      dispatch(getMenuRequest());
    }
  }, [dispatch, menu]);

  const getCompanies = useCallback(() => {
    if (!companies.length) {
      dispatch(findCompaniesRequest());
    }
  }, [companies]);

  useEffect(() => {
    if (failure) {
      dispatch(checkTokenRequest(getToken()));
    }
  }, [failure]);

  useEffect(() => {
    if (isPageLoad) {
      getMenu();
      getCompanies();
    }
  }, [isPageLoad]);

  useEffect(() => {
    if (!signed && isPageLoad) {
      setPageLoad(false);
      dispatch(checkTokenRequest(getToken()));
    }
  }, [dispatch, isPageLoad, signed]);

  function renderContent(): ReactElement {
    let content: ReactElement;

    if (loading || isCheckingToken) {
      content = <LinearProgress />;
    } else {
      content = !signed ? (
        <Main page="HIDDEN">
          <Login />
        </Main>
      ) : (
        <Main page={page}>{children}</Main>
      );
    }

    return content;
  }

  return renderContent();
}

export default ViewPage;
