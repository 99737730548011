import { ActionType } from '../../../@types/action-type';
import { LoginResponse } from '../../../@types/login-response';
import { User } from '../../../interfaces/user';
import { Login } from '../../../interfaces/login';

export const Types = {
  AUTHENTICATION_FAILURE: '@auth/AUTHENTICATION_FAILURE',
  AUTHENTICATION_REQUEST: '@auth/AUTHENTICATION_REQUEST',
  AUTHENTICATION_SUCCESS: '@auth/AUTHENTICATION_SUCCESS',

  CHECK_TOKEN_FAILURE: '@auth/CHECK_TOKEN_FAILURE',
  CHECK_TOKEN_REQUEST: '@auth/CHECK_TOKEN_REQUEST',
  CHECK_TOKEN_SUCCESS: '@auth/CHECK_TOKEN_SUCCESS',

  LOGOUT: 'LOGOUT',

  RESET_STORE: '@auth/RESET_STORE',
};

export function authenticationFailure() {
  return {
    type: Types.AUTHENTICATION_FAILURE,
    payload: {},
  };
}

export function authenticationRequest(login: Login): ActionType<Login> {
  return {
    type: Types.AUTHENTICATION_REQUEST,
    payload: login,
  };
}

export function authenticationSuccess(
  data: LoginResponse,
): ActionType<LoginResponse> {
  return {
    type: Types.AUTHENTICATION_SUCCESS,
    payload: data,
  };
}

export function checkTokenFailure() {
  return {
    type: Types.CHECK_TOKEN_FAILURE,
    payload: false,
  };
}

export function checkTokenRequest(token: string) {
  return {
    type: Types.CHECK_TOKEN_REQUEST,
    payload: token,
  };
}

export function checkTokenSuccess(data: User): ActionType<User> {
  return {
    type: Types.CHECK_TOKEN_SUCCESS,
    payload: data,
  };
}

export function logOut() {
  return {
    type: Types.LOGOUT,
    payload: {},
  };
}

export function resetStore() {
  return {
    type: Types.RESET_STORE,
    payload: {},
  };
}
