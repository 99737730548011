import { all, call, put, takeLatest } from 'redux-saga/effects';
import service from '../../../services/companies';
import { ActionType } from '../../../@types/action-type';
import { Company } from '../../../interfaces/company';
import {
  createCompanyFailure,
  createCompanySuccess,
  findCompaniesFailure,
  findCompaniesSuccess,
  Types,
} from './actions';

export function* create({ payload }: ActionType<Company>) {
  try {
    yield call(service.create, { ...payload });

    yield put(createCompanySuccess());
  } catch (error: any) {
    const {
      data: { message },
    } = error.response;
    yield put(createCompanyFailure(message));
  }
}

export function* findCompanies() {
  try {
    const { data } = yield call(service.findAll);

    yield put(findCompaniesSuccess(data));
  } catch (error: any) {
    const {
      data: { message },
    } = error.response;
    yield put(findCompaniesFailure(message));
  }
}

export default all([
  takeLatest(Types.CREATE_COMPANY_REQUEST, create),
  takeLatest(Types.FIND_COMPANIES_REQUEST, findCompanies),
]);
