export const Types = {
  UPDATE_CNPJ: '@input/UPDATE_CNPJ',
  UPDATE_CPF: '@input/UPDATE_CPF',
};

export function updateCPF(cpf: string) {
  return {
    type: Types.UPDATE_CPF,
    payload: cpf,
  };
}

export function updateCNPJ(cnpj: string) {
  return {
    type: Types.UPDATE_CNPJ,
    payload: cnpj,
  };
}
