import { ActionType } from '../../../@types/action-type';
import { COMMON_STATE } from '../../../constants/state';
import { CommonState } from '../../../interfaces/common-state';
import { User } from '../../../interfaces/user';
import { Types } from './actions';

interface FindUserResponse {
  users: User[];
  actions: boolean;
}
interface FindUsersState extends CommonState {
  users: User[];
  actions: boolean;
}

export const FIND_USERS_DEFAULT_STATE: FindUsersState = {
  ...COMMON_STATE,
  users: [],
  actions: false,
};

export interface UserInitialState {
  isCreating: boolean;
  created: boolean;
  failure: boolean;
  find: FindUsersState;
  update: CommonState;
}

const INITIAL_STATE: UserInitialState = {
  isCreating: false,
  created: false,
  failure: false,
  find: FIND_USERS_DEFAULT_STATE,
  update: COMMON_STATE,
};

function usersReducers(
  state: UserInitialState = INITIAL_STATE,
  action: ActionType = {} as ActionType,
): UserInitialState {
  switch (action.type) {
    case Types.CREATE_USER_FAILURE: {
      return {
        ...state,
        failure: true,
        isCreating: false,
      };
    }
    case Types.CREATE_USER_REQUEST: {
      return {
        ...state,
        created: false,
        failure: false,
        isCreating: true,
      };
    }
    case Types.CREATE_USER_RESET: {
      return {
        ...INITIAL_STATE,
      };
    }
    case Types.CREATE_USER_SUCCESS: {
      return {
        ...state,
        created: true,
        isCreating: false,
      };
    }
    case Types.FIND_USERS_FAILURE: {
      return {
        ...state,
        find: {
          ...state.find,
          failure: true,
          loading: false,
          success: false,
          users: [],
        },
      };
    }
    case Types.FIND_USERS_REQUEST: {
      return {
        ...state,
        find: {
          ...state.find,
          failure: false,
          loading: true,
          success: false,
        },
      };
    }
    case Types.FIND_USERS_RESET: {
      return {
        ...state,
        find: {
          ...state.find,
          failure: false,
          loading: false,
          success: false,
        },
      };
    }
    case Types.FIND_USERS_SUCCESS: {
      const find = action.payload as FindUserResponse;
      return {
        ...state,
        find: {
          ...state.find,
          failure: false,
          loading: false,
          success: true,
          users: find.users,
          actions: find.actions,
        },
      };
    }
    case Types.UPDATE_USER_FAILURE: {
      return {
        ...state,
        update: {
          ...COMMON_STATE,
          failure: true,
          message: {
            message: 'Message.http.user.update.failure',
            severity: 'error',
          },
        },
      };
    }
    case Types.UPDATE_USER_REQUEST: {
      return {
        ...state,
        update: {
          ...COMMON_STATE,
          loading: true,
        },
      };
    }
    case Types.UPDATE_USER_RESET: {
      return {
        ...state,
        update: {
          ...COMMON_STATE,
        },
      };
    }
    case Types.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        update: {
          ...COMMON_STATE,
          success: true,
          message: {
            message: 'Message.http.user.update.success',
            severity: 'success',
          },
        },
      };
    }
    default:
      return state;
  }
}

export default usersReducers;
