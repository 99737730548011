import { LoginResponse } from '../@types/login-response';

const LOGGED_IN_USER = 'LoggedInUser';

export function saveLoggedInUser(data: LoginResponse) {
  localStorage.setItem(LOGGED_IN_USER, JSON.stringify(data));
}

export function getToken(): string {
  const stream = localStorage.getItem(LOGGED_IN_USER);
  const user = stream ? JSON.parse(stream) : '';
  return user.token;
}

export function clearLoggedInUser() {
  localStorage.removeItem(LOGGED_IN_USER);
}
