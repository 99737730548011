import { ActionType } from '../../../@types/action-type';
import { Types } from './actions';

export interface InputInitialState {
  cnpj: string;
  cpf: string;
}

const INITIAL_STATE: InputInitialState = {
  cnpj: '',
  cpf: '',
};

function inputReducers(
  state: InputInitialState = INITIAL_STATE,
  action: ActionType = {} as ActionType,
): InputInitialState {
  switch (action.type) {
    case Types.UPDATE_CNPJ: {
      return {
        ...state,
        cnpj: action.payload as string,
      };
    }
    case Types.UPDATE_CPF: {
      return {
        ...state,
        cpf: action.payload as string,
      };
    }
    default:
      return state;
  }
}

export default inputReducers;
