import React, { ReactElement, useState } from 'react';
import { AppBar, Button, Menu, MenuItem, Toolbar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@stitches/react';
import { useTranslation } from 'react-i18next';
import Container from '../styles/container';
import Logo from '../elements/logo';
import { useAppSelector } from '../hooks/redux';
import LoggedInUser from '../compositions/logged-in-user';
import { CurrentRoute } from '../@types/current-route';
import Span from '../elements/span';

interface HeaderProps {
  page: CurrentRoute;
}

const Ul = styled('ul', {
  padding: '0',
  listStyleType: 'none',
  marginTop: '1rem',
  width: '100%',
});

const Li = styled('li', {
  // eslint-disable-next-line prettier/prettier
  width: '25%',
  // eslint-disable-next-line prettier/prettier
  display: 'inline-block',
  // eslint-disable-next-line prettier/prettier
  color: '#fff',
  // eslint-disable-next-line prettier/prettier
  textAlign: 'center',
  // eslint-disable-next-line prettier/prettier
  alignItems: 'center',
  '&:first-child': {
    textAlign: 'left',
    alignItems: 'left',
  },
  '&.dir': {
    width: 'calc(100% - 190px)',
    float: 'right',
  },
  '@small': {
    'width': '50%',
    '&.admin': {
      display: 'none',
    },
  },
});

const A = styled(Link, {
  maxWidth: '160px',
  minWidth: '80px',
  flex: '1',
  padding: '0px',
  color: '#fff',
  textDecoration: 'none',
});

function Header({ page }: HeaderProps): ReactElement {
  const { signed } = useAppSelector((state) => state.auth);
  const { menu } = useAppSelector((state) => state.home.profileMenu);

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const redirectTo = (link: string) => {
    handleClose();
    navigate(link);
  };

  return (
    <AppBar sx={{ backgroundColor: '#02263d' }}>
      <Toolbar color="#02263d">
        <Container id="container-header">
          <Ul>
            <Li>
              <A to="/">
                <Logo />
              </A>
            </Li>

            {signed && (
              <>
                {menu.map(({ items, label, uri }) => (
                  <Li key={label}>
                    {items.length ? (
                      <>
                        <Button
                          aria-label="account of current user"
                          aria-controls="menu-user"
                          aria-haspopup="true"
                          onClick={handleMenu}
                          color="inherit"
                        >
                          <Span text={t(label)} />
                        </Button>

                        <Menu
                          id={`menu-${label.replace(' ', '_')}`}
                          anchorEl={anchor}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchor)}
                          onClose={handleClose}
                        >
                          {items.map((item) => (
                            <MenuItem
                              key={`menu-item-${item.label.replace(' ', '_')}`}
                              onClick={() => redirectTo(item.uri)}
                            >
                              {t(item.label)}
                            </MenuItem>
                          ))}
                        </Menu>
                      </>
                    ) : (
                      <A to={uri || ''} color="#FFFFFF">
                        {t(label)}
                      </A>
                    )}
                  </Li>
                ))}
              </>
            )}

            <Li>
              <LoggedInUser />
            </Li>
          </Ul>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
