import { ReactNode } from 'react';
import { CurrentRoute } from '../@types/current-route';
import Header from '../components/header';
import { styled } from '../configs/stitches.config';
import Container from '../styles/container';

interface MainProps {
  children: ReactNode;
  page: CurrentRoute;
}

const Body = styled('div', {
  marginTop: '100px',
});

function Main({ children, page }: MainProps) {
  return (
    <>
      <Header page={page} />

      <Body>
        <Container id="main-container">{children}</Container>
      </Body>
    </>
  );
}

export default Main;
