import { useCallback, useEffect, useState } from 'react';
import { DomainAdd } from '@mui/icons-material';
import ModeEditOutline from '@mui/icons-material/ModeEditOutline';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Toast from '../components/toast';
import { Company } from '../interfaces/company';
import {
  createCompanyRequest,
  createCompanyResetStore,
  findCompaniesRequest,
} from '../state/modules/company/actions';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import CompanyForm from './company-form';

type ModalCompanyFormType = 'create' | 'update';

interface ModalCompanyFormProps {
  company?: Company;
  action: ModalCompanyFormType;
}

export default function ModalCompanyForm({
  action,
  company,
}: ModalCompanyFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = useState<boolean>(false);

  const handleToggleModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const { failure, loading, success, message } = useAppSelector(
    (state) => state.company.create,
  );

  const onSubmit = (data: any) => {
    if (action === 'create') {
      dispatch(
        createCompanyRequest({
          ...data,
          cnpj: data.cnpj.replace(/\D/g, ''),
        } as Company),
      );
    }
  };

  const refresh = useCallback(() => {
    dispatch(findCompaniesRequest());
  }, [dispatch]);

  const handleSuccess = useCallback(() => {
    setTimeout(() => {
      dispatch(createCompanyResetStore());
    }, 3000);
    refresh();
    setOpen(false);
  }, [dispatch, refresh]);

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    if (success) {
      handleSuccess();
    }
  }, [success, handleSuccess]);

  return (
    <div>
      {action === 'create' ? (
        <Button
          onClick={handleToggleModal}
          style={{ textTransform: 'capitalize', marginBottom: 18 }}
          variant="contained"
        >
          <DomainAdd style={{ marginRight: 10 }} />
          {t('Label.add')}
        </Button>
      ) : (
        <Button
          onClick={handleToggleModal}
          style={{ textTransform: 'capitalize' }}
        >
          <ModeEditOutline /> {t('Label.edit')}
        </Button>
      )}

      <Dialog open={open}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: 400 }}>
          <DialogTitle>{t(`Title.company.${action}`)}</DialogTitle>
          <DialogContent>
            {open && (
              <CompanyForm
                errors={errors}
                register={register}
                defaultValues={company}
              />
            )}
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 18,
            }}
          >
            <div>
              <Button
                style={{ textTransform: 'capitalize' }}
                onClick={handleToggleModal}
              >
                {t('Label.cancel')}
              </Button>
            </div>

            <div>
              <Button
                fullWidth
                type="submit"
                disabled={loading || !!errors?.password || success}
                variant="contained"
                style={{ textTransform: 'capitalize' }}
              >
                {t('Label.register')}
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>

      <Toast
        // handleClose={closeToast}
        message={message?.message || ''}
        severity={message?.severity || 'info'}
        open={success || failure}
      />
    </div>
  );
}
