import { AxiosResponse } from 'axios';
import { Recruiter } from '../interfaces/recruiter';
import api from './api';

export default {
  create(recruiter: Recruiter): Promise<AxiosResponse> {
    return api.post('/recruiter', recruiter);
  },
  findByCompany(cnpj: string): Promise<AxiosResponse<Recruiter[]>> {
    return api.get(`/recruiter/${cnpj}`);
  },
};
